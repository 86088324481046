import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'

class accessSysPage extends React.Component {
  render() {
    const siteTitle =
      'Gate Access Systems - Arlington, TX and Dallas-Fort Worth, TX'
    const siteDescription =
      'Get a Free Estimate on a Gate Access System When You Call  For Gate Installation in Arlington and Dallas-Fort Worth, TX'

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />

        <div className="container-fluid text-center headline gates-headline">
          <div className="container">
            <h1 className="text-white m-0">Gate Access Systems</h1>
            <hr />
            <p className="text-white mx-auto">
              <strong className="primary-color">
                Serving the entire Dallas-Fort Worth metroplex, J & J Gates
                Service and Design is the leading gate automation company in the
                area.
              </strong>{' '}
              There are many types of controls available to enhance and
              modernize the way a gate operates. Some of these include dial code
              systems that use a keypad with a code for entry, radio remotes
              which allow for the opening and closing of gates from a distance
              with the push of a button, and intercoms that allow for
              communication at the gate entry point. We have the expertise to
              install and repair any and all of these highly advanced methods or
              gate access systems in Dallas-Fort Worth, TX. Call us at{' '}
              <a href="tel:+1-817-466-2794">(817) 466-2794</a> to learn more
              about our options!
            </p>
          </div>
        </div>

        <div className="container d-padding-t d-padding-b access-sys">
          <div className="row">
            <div className="col-md-6">
              <div>
                <h4>Apollo Gate Openers</h4>
                <hr className="ml-0" />
                <p>
                  Apollo is a company that manufactures products of the highest
                  quality. We use Apollo gate openers on many of the gates we
                  install and repair because they are known to last. These
                  openers have the strength to open gates up to 30 feet long and
                  1,000 pounds. Apollo’s electric openers can be controlled with
                  a remote or through a telephone system. They also have battery
                  operated systems that remain charged through the use of solar
                  panels. Solar solutions can dramatically decrease the
                  electricity costs associated with gate access control systems.
                  You can rely on us to install and repair Apollo products in
                  DFW.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <h4>Elite Gate Openers</h4>
                <hr className="ml-0" />
                <p>
                  Our choice of gate openers for industrial gate access systems
                  are those made by Elite. Their automatic gate openers are
                  built with endurance in mind. They are perfect for use at
                  apartment complexes and other gated communities where there is
                  heavy traffic going in and out of the gates all day. We also
                  use Elite’s heavy-duty devices for commercial gates where we
                  know that any malfunction could result in the loss of money
                  and time.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <h4>LiftMaster Gate Openers</h4>
                <hr className="ml-0" />
                <p>
                  For our customers who want to get the longest life out of
                  their gate access systems, we recommend LiftMaster gate
                  openers. They come in several different models including some
                  which use DC power and some which use solar power. If you want
                  your automatic gate opener to work for years, without needing
                  to be repaired, one made by LiftMaster may be right for you.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <h4>Free Estimates!</h4>
                <hr className="ml-0" />
                <p>
                  Regardless of the type of gate access system you choose to
                  employ, J & J Gates Service and Design is the right company
                  for you. We provide skilled installation and maintenance and
                  offer expert solutions to meet any residential or commercial
                  need.{' '}
                  <strong>
                    Contact us at{' '}
                    <a href="tel:+1-817-466-2794">(817) 466-2794</a> today to
                    learn more and to receive a free estimate for access gate
                    installation in Dallas and Fort Worth, TX.
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default accessSysPage

export const accessSysPageQuery = graphql`
  query accessSysPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
